import React from 'react';


function Errores() {

    return (
        <div>
          <h2>Esta página no existe</h2>
        </div>
    );

}

export default Errores;